:root {
  // --primary: #00b0f0;
  --primary: #1e4fed;
  --light-blue: #2082ca;
  --dark-blue: #29256f;
  --secondary: #f05a28;
  --secondary-hover: #e24410;
  --black: #000;
  --white: #fff;
  --unread: #d6ebff;
  --gray: #e0dfdc;
  --white-smoke: #f5f5f5;
  --dark-gray: #8d8f94;
  --success: #1a8d08;
  --green: #73cb57;
  --purple: #7e57c2;
  --scroll-border: #d3d3d3;
  --light-gray: #f2f2f2;
  --default-box-shadow: 0 2px 15px 0 #dcdcdc;
  --gradient-background: linear-gradient(to right, #f3b605 0%, #ff6b5e 100%);
  --padding-desktop: 60px 0;
  --padding-desktop-large: 90px 0;
}

@mixin card-bg {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: var(--white-smoke);
  background: hsla(0deg, 0%, 100%, 0.98);
  border: 1px solid #efefef;
  -webkit-box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.01%), 0 -2px 16px 0 rgba(0, 0, 0, 0.05%);
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0%), 0 -2px 16px 0 rgba(0, 0, 0, 0.05%);
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

// webkit scrollbar starts

body::-webkit-scrollbar-track,
.accounting-dashboard ::-webkit-scrollbar-track,
.role-management::-webkit-scrollbar-track,
.ant-table-content::-webkit-scrollbar-track,
.workflow.app-sidebar .workflow-container::-webkit-scrollbar-track,
.workflow-view-container::-webkit-scrollbar-track,
.custom-template-left::-webkit-scrollbar-track,
.custom-template-center::-webkit-scrollbar-track,
.custom-template-right::-webkit-scrollbar-track,
.right-field-details::-webkit-scrollbar-track {
  background-color: var(--white-smoke);
  border-radius: 10px;

  // -webkit-box-shadow: inset 0 0 6px var(--white-smoke);
}

body::-webkit-scrollbar,
.accounting-dashboard ::-webkit-scrollbar,
.role-management::-webkit-scrollbar,
.ant-table-content::-webkit-scrollbar,
.workflow.app-sidebar .workflow-container::-webkit-scrollbar,
.workflow-view-container::-webkit-scrollbar,
.custom-template-left::-webkit-scrollbar,
.custom-template-center::-webkit-scrollbar,
.custom-template-right::-webkit-scrollbar,
.right-field-details::-webkit-scrollbar {
  background-color: var(--white-smoke);
  height: 3px;
  width: 3px;
}

body::-webkit-scrollbar-thumb,
.accounting-dashboard ::-webkit-scrollbar-thumb,
.role-management::-webkit-scrollbar-thumb,
.ant-table-content::-webkit-scrollbar-thumb,
.workflow.app-sidebar .workflow-container::-webkit-scrollbar-thumb,
.workflow-view-container::-webkit-scrollbar-thumb,
.custom-template-left::-webkit-scrollbar-thumb,
.custom-template-center::-webkit-scrollbar-thumb,
.custom-template-right::-webkit-scrollbar-thumb,
.right-field-details::-webkit-scrollbar-thumb {
  background-color: var(--scroll-border);
  border-radius: 10px;

  // -webkit-box-shadow: inset 0 0 6px var(--white-smoke);
}

// webkit scrollbar ends

body {
  // font-family: arial, sans-serif, tahoma;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto, sans-serif;
  font-weight: 600;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

hr {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #b6b6b6;
  box-sizing: content-box;
  height: 0;
  margin: 16px 0;
  overflow: visible;
}

.border-top {
  border-top: 1px solid #ccc;
}

.box-shadow {
  box-shadow: var(--default-box-shadow);
}

.ant-layout-header {
  -webkit-box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
  -moz-box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
  box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
  z-index: 9;
}

.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  border-bottom: 2px solid var(--secondary);
  color: var(--white);
}

.ant-layout-header.fixed-header {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.default-spacing {
  margin-top: 54px;
}

button {
  border-radius: 5px;
}

h2 {
  font-size: 21px;
}

.margin-top20 {
  margin-top: 20px;
}

.margin-bottom20 {
  margin-bottom: 20px;
}

a.standard,
button,
button.primary,
.ant-btn-primary,
.ant-btn-standard,
.ant-btn-secondary,
.ant-btn-button.secondary {
  border-radius: 3px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  outline: none;
  text-shadow: none;
  width: auto;
}

a.standard,
.ant-btn-primary,
.ant-btn-secondary {
  // box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
  color: var(--white);
  font-weight: 500;
}

a.standard,
.ant-btn-primary,
.btn-glow.primary {
  background: #a3243d;
  border: transparent;
  color: var(--white);
  font-weight: bold;
}

.ant-btn-primary:hover,
.btn-glow.primary:hover,
.ant-btn-primary:focus {
  background: #a3243d;
  border-color: #374464;
}

.ant-btn-secondary,
.ant-btn-secondary:hover,
.btn-primary {
  background: var(--primary);
  border: 1px solid var(--primary);
  color: var(--white);
  font-weight: bold;
}

.ant-btn-button.secondary {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  color: var(--white);
}

.btn-white {
  background: var(--white);
  border: 1px solid var(--white);
  color: var(--primary);
}

.btn-coloured {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  color: #fff;
  display: inline-block;
  text-align: center;
}

.btn-coloured:hover {
  background-color: var(--secondary-hover);
  color: var(--white);
}

.btn-large {
  border-radius: 3px;
  font-size: 16px;
  padding: 12px 31px;

  @media (max-width: 360px) {
    width: 100%;
  }
}

.btn-right-space {
  margin-right: 20px;

  @media (max-width: 600px) {
    margin-right: 0;
  }
}

.btn-border-white {
  background-color: transparent;
  border: 1px solid var(--white);
  color: var(--white);
}

button i {
  margin-right: 5px;
}

button i.no-margin {
  margin-right: 0;
}

// side menu changes starts

.ant-layout-sider {
  background: linear-gradient(170.88deg, #fbfbfb 0%, #fbfbfb 100%);
  box-shadow: 0 2px 10px 0 rgba(74, 74, 74, 0.5);
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: transparent;
}

.ant-menu {
  font-size: 15px;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 28px;
  line-height: 28px;
}

.main-header .ant-menu-submenu-title span {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
}

.brand-logo {
  background-color: #374464 !important;
}

.brand-logo img {
  height: 35px;
}

.ant-menu li .icons {
  margin-right: 7px;
}

// side menu changes ends here

// container-fluid starts

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

// container-fluid ends

// input fields starts

input[type="text"],
input[type="password"],
input[type="number"],
input[type="date"],
.ant-picker,
select {
  border-radius: 3px;
  font-size: 14px;
  height: 35px;
  outline: none;
  padding: 6px 8px;
  position: relative;
  width: 100%;
}

.ant-input {
  border-radius: 3px;
}

input[type="text"],
input[type="text"]:hover,
input[type="text"]:focus {
  outline: none;
}

.ant-input-affix-wrapper {
  border-radius: 3px;
  height: 35px;
}

.ant-select-multiple .ant-select-selector {
  height: auto !important;
}

.ant-select-selection-item {
  color: #262626;
}

.ant-select-selector {
  border-radius: 3px !important;
  height: 35px !important;
}

.ant-select.select-after.ant-select-single.ant-select-show-search {
  min-width: 40px !important;
}

.ant-input-affix-wrapper input[type="text"],
.ant-input-affix-wrapper input[type="password"] {
  height: auto;
}

.ant-checkbox-wrapper {
  font-size: 14px;
}

// input fields ends here

.ant-layout {
  background: #fff;
}

// header changes

.ant-layout-header {
  background: #374464;

  // background: var(--white);
  border-radius: 0;
  color: #333;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#2c3742", endColorstr="#28303a", GradientType=0);
  height: 54px;
  line-height: 52px;
  margin-bottom: 0;
  min-height: 54px;
  padding: 0 12px 0 0 !important;
}

.ant-layout-header .ant-menu {
  background: transparent;
}

// headder changes ends

// font size starts

.ant-tabs-tab,
.ant-tabs {
  font-size: 15px;
}

// font size ends

// Top menu section after login

.left-menu {
  float: left;
  width: 75%;
}

.left-menu .main-title {
  width: 100%;
}

.left-menu .main-title h1 {
  font-weight: 500;
  margin-bottom: 0;
}

.left-menu .search {
  margin-top: 10px;
  padding-right: 10px;
  width: 100%;
}

.action-icons {
  width: 100%;
}

.action-icons ul {
  display: inline-block;
  list-style: none;
  margin-bottom: 0;
  padding: 0 10px 0 0;
  width: 100%;
}

.action-icons ul li {
  display: inline-block;
  padding: 0 10px;
}

.action-icons ul li a {
  line-height: 64px;
  padding: 6px;
}

.action-icons ul li a.add-new {
  border: 1px solid #fbfbfb;
  border-radius: 5px;
  padding: 5px 20px;
}

.action-icons ul li button i {
  margin-right: 7px;
}

// Top menu section after login ends

// inner pages css starts
.breadcrum-title.without-bg {
  background-image: unset;
}

.breadcrum-title.only-heading {
  background-image: url("../assets/images/accqrate-pattern-text-blue-half.svg");
  background-position: 89% 100%;
  background-repeat: no-repeat;
  background-size: 51%;

  @media (max-width: 600px) {
    background-size: 80%;
  }
}

.breadcrum-title {
  background-color: #f5f5f5;
  background-image: url("../assets/images/accqrate-pattern-text-gray-half.svg");
  background-position: 10% 100%;
  background-repeat: no-repeat;
  background-size: 72%;
  float: left;
  overflow: hidden;
  padding-top: 40px;
  position: relative;
  width: 100%;

  h1 {
    color: var(--black);
    font-size: 30px;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 15px;

    @media (max-width: 900px) {
      font-size: 26px;
    }
  }

  img {
    display: block;
    height: 260px;
    margin: 0 auto;

    @media (max-width: 600px) {
      width: 95%;
    }
  }

  p {
    color: #575757;
    font-size: 19px;
  }

  .breadcrum-content {
    padding-bottom: 33px;

    @media (max-width: 780px) {
      padding-bottom: 20px;
    }
  }

  @media (max-width: 900px) {
    padding: 30px 0;
  }
}

.breadcrum-title.text-center {
  text-align: center;

  h1 {
    font-size: 37px;
    text-align: center;
  }
}

// order-breadcrumb

.order-breadcrumb {
  background: #f5f5f5;
  padding: 20px 0;
}

.inner-pages {
  background-color: #fff;
  float: left;
  padding: 60px 0;
  width: 100%;

  p,
  ul {
    font-size: 16px;
    line-height: 181%;
  }

  h3 {
    font-size: 22px;
  }
}

.full-width-section {
  display: block;
  float: left;
  width: 100%;
}

// inner pages css ends

.inner-pages.about-us h1 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}

.inner-pages.about-us p {
  color: #000;
  font-size: 17px;
  line-height: 180%;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 27px;
  line-height: 28px;
}

// footer starts

.ant-layout-footer {
  background: var(--black);
  color: #fff;
  padding: 16px 50px;
  z-index: 1;

  p {
    @media (max-width: 900px) {
      margin-bottom: 10px;
    }
  }

  p,
  ul li a {
    color: #fff;
  }

  @media (max-width: 900px) {
    margin-top: 30px;
    padding: 30px 20px;
    text-align: center;
  }
}

.inner-contents {
  margin-left: 180px;
  padding: 30px 0;

  .custom-background {
    padding: 0;
  }
}

.inner-contents .save-changes,
.fixed-action-area {
  @include card-bg;

  bottom: 0;
  left: 0;
  padding: 10px;
  position: fixed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.inner-contents .save-changes,
.fixed-action-area {
  z-index: 99;
}

.app-sidebar {
  position: relative;
}

.app-sidebar .custom-table .custom-table-button .anticon {
  margin-left: 0;
}

.app-sidebar .custom-table .custom-table-button {
  border-radius: 4px;
  padding: 4px 11px;
}

// default input fields height

.filter-section .ant-select:not(.ant-select-auto-complete) .ant-select-selector,
.filter-section .ant-picker,
.filter-section .ant-input-affix-wrapper,
.filter-section .custom-input-box input {
  -webkit-appearance: none;
  background: transparent;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px !important;
  color: #737f8d;
  font-size: 12px;
  height: 29px !important;
  line-height: inherit;
  padding: 1px 7px 0;
  vertical-align: middle;
}

// default input fields height ends

.ant-select-multiple .ant-select-selector {
  height: auto !important;
}

.status.badge {
  border-radius: 10px;
  -webkit-box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 82%;
  min-width: 100px;
  padding: 2px 10px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}

.status.badge {
  &.pending,
  &.created {
    background: #ff9800;
    background: -moz-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffb64a), color-stop(100%, #ff9800));
    background: -webkit-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
    background: -o-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
    background: -ms-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
    background: linear-gradient(to bottom, #ffb64a 0%, #ff9800 100%);
  }
}

.status.badge.issued {
  background: #f85032;
  background: -moz-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
  background:
    -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, #f85032),
      color-stop(0%, #f16f5c),
      color-stop(51%, #f6290c),
      color-stop(71%, #f02f17),
      color-stop(100%, #e73827)
    );
  background: -webkit-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
  background: -o-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
  background: -ms-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
  background: linear-gradient(to bottom, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
}

.status.badge.sent {
  background: #ffc107;
  background: -moz-linear-gradient(top, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
  background:
    -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, #ffc107),
      color-stop(0%, #ebb30b),
      color-stop(51%, #d5a411),
      color-stop(71%, #c29716),
      color-stop(100%, #b28b19)
    );
  background: -webkit-linear-gradient(top, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
  background: -o-linear-gradient(top, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
  background: -ms-linear-gradient(top, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
  background: linear-gradient(to bottom, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
}

.status.badge.partiallypaid {
  background: #f85032;
  background: -moz-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
  background:
    -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, #f85032),
      color-stop(0%, #f16f5c),
      color-stop(50%, #f6290c),
      color-stop(71%, #82bc40),
      color-stop(100%, #47761d)
    );
  background: -webkit-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
  background: -o-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
  background: -ms-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
  background: linear-gradient(to right, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
}

.status.badge.paid {
  background: #82bc40;
  background: -moz-linear-gradient(top, #82bc40 0%, #47761d 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #82bc40), color-stop(100%, #47761d));
  background: -webkit-linear-gradient(top, #82bc40 0%, #47761d 100%);
  background: -o-linear-gradient(top, #82bc40 0%, #47761d 100%);
  background: -ms-linear-gradient(top, #82bc40 0%, #47761d 100%);
  background: linear-gradient(to bottom, #82bc40 0%, #47761d 100%);
}

.status.badge.dropped {
  background: #ff9800;
  background: -moz-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffb64a), color-stop(100%, #ff9800));
  background: -webkit-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
  background: -o-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
  background: -ms-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
  background: linear-gradient(to bottom, #ffb64a 0%, #ff9800 100%);
}

// status ends here

// button starts

.ant-table-cell .btn-group > .btn.glow.dropdown-toggle {
  height: 28px;
}

.btn-group,
.btn-group-vertical {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.btn-group > .btn.glow.dropdown-toggle {
  padding: 6px 9px;
}

.btn-group > .btn.glow:hover {
  background: #fefefe;
  background: -moz-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background:
    -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #fefefe),
      color-stop(100%, #f7f7f7)
    );
  background: -webkit-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: -o-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: -ms-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: linear-gradient(to bottom, #fefefe 0%, #f7f7f7 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#fefefe", endColorstr="#f7f7f7", GradientType=0);
}

.btn-group button.glow:hover {
  color: #a8b5c7;
}

.btn-group button.glow:active,
.btn-group button.glow.active {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) inset;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) inset;
  color: #a8b5c7;
}

.btn {
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.btn-group > .btn.glow {
  border: 1px solid #d0dde9;
  color: #313d4c;
  float: left;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 6px 14px;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group button.glow {
  background: #fefefe;
  background: -moz-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background:
    -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #fefefe),
      color-stop(100%, #f7f7f7)
    );
  background: -webkit-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: -o-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: -ms-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: linear-gradient(to bottom, #fefefe 0%, #f7f7f7 100%);
  border: 1px solid #d0dde9;
  color: #313d4c;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#fefefe", endColorstr="#f7f7f7", GradientType=0);
  float: left;
  font-family: "Open sans", Helvetica, Arial;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  padding: 5px 10px;
  -moz-transition: color 0.1s linear;
  -webkit-transition: color 0.1s linear;
  -o-transition: color 0.1s linear;
  transition: color 0.1s linear;
}

.caret {
  border-bottom: 0 dotted;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  width: 0;
}

.btn-glow {
  background: #fff;
  background: -moz-linear-gradient(top, #fff 0%, #eef0f1 100%);
  background:
    -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #fff),
      color-stop(100%, #eef0f1)
    );
  background: -webkit-linear-gradient(top, #fff 0%, #eef0f1 100%);
  background: -o-linear-gradient(top, #fff 0%, #eef0f1 100%);
  background: -ms-linear-gradient(top, #fff 0%, #eef0f1 100%);
  background: linear-gradient(to bottom, #fff 0%, #eef0f1 100%);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  padding: 5px 10px;
  text-align: center;
  vertical-align: middle;
}

.btn-block {
  display: block;
  width: 100% !important;
}

.btn-glow.primary {
  i {
    line-height: 10px;
    margin-right: 5px;
  }
}

a.btn-glow.primary {
  color: var(--white);
}

.btn-glow.success {
  background: #96bf48;
  border: 1px solid #99bd56;
  color: #fff;
}

.btn-glow.delete-field {
  background: linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -moz-linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -webkit-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -webkit-linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -o-linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -ms-linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  border: 1px solid #e11117;
  color: #fff;
}

// button ends

// page not found starts
.page-not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: center;
}

.page-not-found img {
  height: auto;
  margin-bottom: 18px;
  width: 130px;
}

.page-not-found h1 {
  font-size: 35px;
  font-weight: bold;
}

.page-not-found .btn-glow {
  font-size: 18px;
}

.page-not-found .btn-glow i {
  margin-right: 6px;
}

// page not found ends here

// loading content starts

.loading-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: center;
}

.loading-content img {
  height: auto;
  margin-bottom: 20px;
  width: 200px;
}

.loading-content h1 {
  font-size: 22px;
  font-weight: 600;
}

// loading content ends

.align-right {
  text-align: right;
}

.top-filter-options {
  h2 {
    color: var(--primary);
    font-size: 20px;
    margin-bottom: 14px;
    text-transform: uppercase;

    @media (max-width: 900px) {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.content-container {
  padding: 0 14px;
}

.content-container .custom-background {
  padding: 0 !important;
}

// model for arrange column starts

.mobile-filter {
  display: none;
  left: 0;
  position: fixed;
  top: 40%;
  z-index: 999;

  @media (max-width: 900px) {
    display: block;
  }
}

.mobile-filter .btn-glow {
  padding: 7px 9px;
}

// add-new-invoice-button starts

.add-new-invoice-button {
  display: none;
  margin-top: 15px;
  width: 100%;

  @media (max-width: 900px) {
    display: block;
  }
}

// add-new-invoice-button ends

.ant-collapse > .ant-collapse-item {
  background: #fff !important;
  background:
    -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, #fff),
      color-stop(47%, #f6f6f6),
      color-stop(100%, #ededed)
    ) !important;
  background: linear-gradient(to bottom, #fff 0%, #f6f6f6 47%, #ededed 100%) !important;
  border-bottom: 1px solid #c6d2d9;
}

// add-proj-timesheet starts

.add-proj-timesheet {
  width: 100%;

  .form-fields {
    margin-bottom: 10px;

    label {
      display: inline-block;
      font-weight: 600;
      margin-bottom: 6px;
    }
  }

  .add-proj {
    color: var(--primary);
    cursor: pointer;
    float: right;
    font-size: 15px;
    font-weight: bold;
    text-align: right;

    span {
      margin-right: 4px;
    }
  }

  #totalHours {
    // width: 110px;
    color: var(--primary);
    font-size: 20px;
    font-weight: bold;
    padding: 22px 20px;
  }
}

// form fields

.form-fields,
.ant-modal-content .form-field {
  margin-bottom: 13px;
}

.form-fields label,
.ant-modal-content .form-field label {
  font-weight: bold;
}

.ant-radio-group label {
  font-weight: normal;
}

.ant-modal-content .form-field label {
  font-size: 12px;
  margin-bottom: 3px;
}

// stop timer

.stop-timer {
  background: #d3d3d3;
  border-radius: 5px;
  color: var(--primary);
  cursor: move;
  display: none;
  padding: 0 10px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 57px;
  width: fit-content;
  z-index: 9;

  .stop-icon {
    img {
      cursor: pointer;
      height: 27px;
      margin-left: 8px;
      margin-top: 0;
      width: auto;
    }
  }

  #time-count {
    font-size: 20px;
    font-weight: bold;
  }
}

.ant-tabs-nav .ant-tabs-tab-btn {
  font-size: 15px;
}

.ant-tabs-nav .ant-tabs-ink-bar {
  background: #a3243d;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #a3243d;
  font-weight: bold;
}

.top-title-section {
  margin-bottom: 7px;
  margin-top: 23px;
  width: 100%;

  h2 {
    font-size: 19px;
    font-weight: 600;
  }
}

// ant steps

.ant-steps-navigation {
  padding-top: 0;
}

.ant-steps-navigation .ant-steps-item-container {
  padding-bottom: 5px;
}

.ant-steps-navigation .ant-steps-item::before,
.ant-steps-item-process .ant-steps-item-icon .ant-steps-item-title {
  background-color: #f49f10;
  height: 3px;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: var(--secondary);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #fff;
  font-size: 13px;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #fff;
  font-size: 13px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #f49f10;
  font-size: 14px;
  font-weight: bold;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f49f10;
}

.ant-steps-navigation .ant-steps-item::after {
  border: 2px solid #fff;
  border-bottom: none;
  border-left: none;
  padding-top: 5px;
}

// profile name

.profile-name {
  color: var(--white);
  text-align: center;

  span {
    font-weight: bold;
  }
}

// sider
.mobile-sider-menu {
  height: 50px;

  @media (max-width: 900px) {
    display: none;
    position: absolute;
    z-index: 2;
  }
}

.required {
  color: #f44336;
  margin-left: 4px;
}

.ant-input[disabled],
.ant-picker-input > input[disabled] {
  color: #262626;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.add-proj-timesheet {
  .submitted {
    color: var(--primary);
  }

  .approved {
    color: var(--success);
  }
}

// upload-invoice-expense starts

.upload-invoice-expense-note {
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 #e4e4e4;
  font-size: 13px;
  line-height: 184%;
  margin-bottom: 30px;
  padding: 20px 24px;
  position: relative;
  width: 100%;

  .heading {
    // background-image: linear-gradient(#fff, #eef0f1);
    border-bottom: 1px solid #f1f2f3;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 24px;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -20px;
    padding: 10px 20px;
    position: relative;

    h2 {
      color: var(--black);
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}

.upload-invoice-expense {
  .sample-template {
    margin-bottom: 15px;
    text-align: center;

    .ant-btn-primary {
      height: 32px;
    }
  }

  .custom-background {
    padding: 0;

    .custom-table {
      border: 1px solid var(--gray);
      border-radius: 5px;
      padding: 0 0 10px;
    }
  }
}

.custom-table {
  thead > tr > td {
    @media (max-width: 900px) {
      min-width: 100px;
    }
  }

  @media (max-width: 900px) {
    border: 1px solid #ddd;
    overflow-x: auto;
  }
}

.ant-upload {
  width: 100% !important;
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  .anticon svg {
    color: red;
    font-size: 1rem;
    font-weight: 900;
  }
}

.fixed-action-area {
  text-align: center;

  button {
    margin: 0 5px;
  }
}

// upload field starts

.file-upload-field {
  background: #fbfbfb;
  border: 1px dashed #b3b3b3;
  border-radius: 5px;
  color: red;
  cursor: pointer;
  padding: 20px;
  text-align: center;

  i {
    color: var(--secondary);
  }
}

// upload field ends

.list-field {
  border-top: 1px solid #ededed;
  display: flex;
  margin-bottom: 3px;
  padding-bottom: 8px;
  padding-top: 20px;
  position: relative;

  >.ant-row {
    flex: 1;
  }

  .remove-column {
    .btn-glow.delete-field {
      color: red;
      padding: 0 5px;
    }
  }
}

.list-field:first-child {
  border-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  position: relative;
}

.dashboard {
  background-image: url("../assets/housinng.bt logo and color palettes/housinng.bt logo and color palettes/Housing_Bt logo/PNG/bbb.jpg");
  background-size: 1700px;
  width: 100%;
}

.screen {
  margin-left: auto;
  margin-right: auto;
  width: 60%;

  .beta {
    background-color: #a3243d;
    color: #fff;
    float: right;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    width: 20%;
  }
}

.online-tools {
  color: #374464;
  font-size: 35px;
  font-weight: bold;
  margin-top: 60px;
}

.property {
  color: #f49f10;
  font-size: 35px;
  font-weight: bold;
}

.line {
  color: #374464;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}

.line-btn {
  color: #374464;
  font-size: 14px;
  font-weight: bold;
}

.line-btn:hover {
  color: #a3243d;
}

.brokers {
  color: #f49f10;
}

.get-started {
  background-color: #f49f10;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  margin-top: 15px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  text-align: center;
}

.get-started:hover {
  border-color: #a3243d;
  color: #a3243d;
}

.e-genja {
  background-color: #374464;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 15px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  text-align: center;
}

.e-genja:hover {
  border-color: #a3243d;
  color: #a3243d;
}

.details {
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: var(--default-box-shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 30px;
  padding: 25px;
}

.card-name {
  color: #a3243d;
  font-size: 17px;
  font-weight: bold;
  padding-top: 10px;
}

.card-name:hover {
  color: #374464;
}

.card-name1 {
  color: #f49f10;
  font-size: 17px;
  font-weight: bold;
  padding-top: 10px;
}

.card-name1:hover {
  color: #374464;
}

.card-content {
  color: #000;
  font-size: 13px;
  font-weight: bold;
  padding-top: 10px;
}

.card-btn {
  color: #a3243d;
  font-size: 13px;
  font-weight: bold;
  padding-top: 10px;
}

.card-btn1 {
  color: #f49f10;
  font-size: 13px;
  font-weight: bold;
  padding-top: 10px;
}

.card-btn:hover {
  color: #374464;
}

.card-btn1:hover {
  color: #374464;
}

.think {
  background-color: #374464;
  padding-bottom: 40px;
  padding-top: 40px;

  .content {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }

  .header {
    color: #f49f10;
    font-size: 18px;
    font-weight: bold;
  }

  .header-1 {
    color: #fd0233;
    font-size: 18px;
    font-weight: bold;
  }

  .header-2 {
    color: gray;
    font-size: 18px;
    font-weight: bold;
  }

  .body {
    color: #fff;
    font-size: 15px;
  }
}

.ad {
  margin-top: 20px;
  text-align: center;
}

.check {
  color: #374464;
  font-size: 15px;
}

.properties {
  color: #a3243d;
  font-size: 30px;
  font-weight: bold;
}

.explore {
  color: #374464;
  font-size: 13px;
}

.review {
  background-color: lightgrey;
  font-size: 30px;
  font-weight: bold;
  margin-top: 50px;
  text-align: center;
}

.link-up {
  background-color: #fff;
}

.exp-of-work {
  color: #374464;
  font-size: 15px;
  text-align: center;
}

.industry-leaders {
  color: #a3243d;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.industry {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.news {
  background-color: lightgrey;
  text-align: center;

  .recent {
    color: #374464;
    font-size: 15px;
    padding-top: 30px;
  }

  .news-articles {
    color: #a3243d;
    font-size: 30px;
    font-weight: bold;
  }
}

.founder {
  color: #f49f10;
  font-size: 25px;
  font-weight: bold;
  margin-top: 15px;
}

.journey {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin-top: 16px;
}

.website-link {
  color: #a3243d;
  font-size: 13px;
  font-weight: bold;
}

.vision-border {
  background-color: #f49f10;
  margin-top: 25px;
  padding: 10px;
  width: 60%;
}

.vision-box {
  background-color: #374464;
  padding: 20px;
}

.vision-header {
  color: #f49f10;
  font-size: 18px;
  font-weight: bold;
  padding-top: 10px;
}

.vision-body {
  color: #fff;
  font-size: 15px;
  padding-bottom: 10px;
  padding-top: 20px;
}

.mission-border {
  background-color: #374464;
  margin-top: 25px;
  padding: 10px;
  width: 60%;
}

.mission-box {
  background-color: #f49f10;
  padding: 20px;
}

.mission-header {
  color: #374464;
  font-size: 18px;
  font-weight: bold;
  padding-top: 10px;
}

.mission-body {
  color: #000;
  font-size: 15px;
  line-height: 25px;
  padding-bottom: 10px;
  padding-top: 20px;
}

.contact-icons {
  color: gray;
  font-size: 20px;
  margin-left: 30px;
  position: relative;
  top: 30px;
}

.contact-btn {
  background-color: #000;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
  height: 40px;
}

.contact-btn:hover {
  background-color: #f49f10;
  border-color: #f49f10;
  color: #fff;
}

.winners {
  width: 100%;

  .congrats {
    font-size: 35px;
    font-weight: bold;
    margin-top: 40px;
    text-align: center;

    .result {
      font-size: 18px;
      font-weight: bold;
      margin-top: 15px;
    }

    .congrats-content {
      font-size: 13px;
      font-weight: lighter;
      margin-bottom: 30px;
      margin-top: 15px;
    }
  }

  .body {
    background-color: #f49f10;

    .card {
      margin-left: auto;
      margin-right: auto;
      width: 60%;
    }

    .second {
      background-color: #040d4e;
      border: 2px solid #fff;
      border-radius: 10px;
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      margin-right: 25px;
      margin-top: 75px;
      text-align: center;

      // width: 300px;
    }

    .first {
      background-color: #fff;
      border: 2px solid #000;
      border-radius: 10px;
      color: #040d4e;
      font-size: 15px;
      font-weight: bold;
      margin-right: 25px;
      margin-top: 50px;
      text-align: center;

      // width: 330px;
    }

    .footer {
      color: #fff;
      font-size: 20px;
      margin-top: 50px;
      text-align: center;
    }

    .btn {
      background-color: #040d4e;
      color: #fff;
      left: 40%;
      margin-bottom: 50px;
      margin-top: 50px;
    }

    .btn:hover {
      border-color: #a3243d;
    }
  }
}

.agreement {
  width: 100%;

  .head {
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 60%;

    .title {
      margin-top: 30px;
    }

    .header {
      color: #a3243d;
      font-size: 35px;
      font-weight: bold;
      margin-left: 25px;
    }

    .header-1 {
      color: #000;
      font-size: 35px;
      font-weight: bold;
    }

    .header-3 {
      color: #d81159;
      font-size: 35px;
      font-weight: bold;
      margin-left: 25px;
    }

    .message {
      font-size: 15px;
      font-weight: bold;
      margin-left: 25px;
    }

    .card {
      align-items: center;
      border: 1px solid lightgrey;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;
      margin-right: 20px;
      padding: 20px;
      text-align: center;
    }

    .cards {
      align-items: center;
      border: 1px solid lightgrey;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;
      margin-right: 20px;
      padding: 20px;
      text-align: center;
    }

    .cards-1 {
      align-items: center;
      border: 1px solid lightgrey;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;
      margin-right: 20px;
      padding: 20px;
      text-align: center;
    }

    .cards-2 {
      align-items: center;
      border: 1px solid lightgrey;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;
      margin-right: 20px;
      padding: 20px;
      text-align: center;
    }

    .card-head {
      color: #000;
      font-size: 17px;
      font-weight: bold;
    }

    .card-body {
      color: #000;
      font-size: 14px;
    }

    .card-body:hover {
      color: #a3243d;
    }

    .card-btn {
      color: gray;
      margin-bottom: 20px;
    }

    .card-btn:hover {
      color: #a3243d;
    }
  }

  .body {
    background-color: #374464;
    color: #fff;
  }

  .content {
    font-size: 15px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 60%;
  }

  .samten {
    color: #f49f10;
    font-size: 25px;
    font-weight: bold;
  }

  .faqs {
    color: #a3243d;
    font-size: 25px;
    font-weight: bold;
    margin-left: 25px;
  }

  .faqs-0 {
    color: #000;
    font-size: 25px;
    font-weight: bold;
  }

  .faqs-1 {
    color: #000;
    font-size: 25px;
    font-weight: bold;
  }

  .faqs-2 {
    margin-left: 30px;
    margin-top: 30px;
  }

  .faqs-content {
    color: gray;
    font-size: 15px;
    font-weight: bold;
  }

  .faqs-content-body {
    color: gray;
    font-size: 13px;
    font-weight: lighter;
  }
}

.buy-completed {
  border: 1px solid #000;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  padding: 50px;
  text-align: center;
  width: 60%;

  .percent {
    font-size: 30px;
    font-weight: bold;
  }

  .body {
    font-size: 20px;
    font-weight: bold;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
  }

  .message {
    font-size: 15px;
    margin-top: 20px;
  }
}

.finish-line-100 {
  background-color: #fe6847;
  border-radius: 10px;
  height: 5px;
  margin-left: 5%;
  margin-top: 10px;
  width: 90%;
}

.sell-register-completed {
  margin: auto;
  margin-top: 150px;
  text-align: center;
  width: 60%;

  .percent {
    color: #000;
    font-size: 40px;
    font-weight: bold;
    margin-top: 25px;
  }

  .body {
    color: #000;
    font-size: 20px;
    font-weight: bold;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
  }

  .message {
    font-size: 15px;
    margin-top: 20px;
  }

  .message1 {
    font-size: 17px;
    font-weight: bold;
    margin-top: 20px;
  }
}

.properties {
  width: 100%;

  .content {
    background:
      linear-gradient(
        280deg,
        #3a415d 0%,
        #a4243d 100%
      );
    height: 300px;
    padding-top: 100px;
  }

  .search-box {
    background: #f49f10;
    border: 2px solid #fff;
    border-radius: 10px;
    margin: auto;
    width: 60%;
  }

  .field {
    margin-bottom: 30px;
    margin-left: 20px;
  }

  .form-field {
    position: relative;

    label {
      color: #333;
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
      line-height: 21px;
    }
  }

  .btn {
    background-color: #374464;
    color: #fff;
    font-weight: bold;
    margin-left: 5px;
    margin-top: 60px;
  }

  .ad {
    .check {
      color: #000;
      font-size: 40px;
      font-weight: bold;
    }

    .properties {
      color: #24a9e1;
      font-size: 35px;
      font-weight: bold;
    }

    .image-view {
      background-color: #f49f10;
      border-radius: 5px;
      bottom: 16px;
      font-size: 14px;
      font-weight: bold;
      padding-left: 15px;
      padding-right: 15px;
      position: absolute;
      right: 16px;
    }

    .property-search {
      background-color: #dbdbdb;
      font-size: 13px;
      height: 120px;
    }
  }
}

// Panel Layout starts
.panel-layout {
  margin-bottom: 50px;

  h2 {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  .panel-title {
    background-color: #374464;
    color: white;
    font-size: 25px;
    margin-bottom: 14px;
    padding: 30px;
    text-transform: uppercase;
    width: 60%;
  }

  .content {
    line-height: 25px;
    margin-bottom: 20px;
    width: 60%;
  }

  .content-1 {
    line-height: 25px;
    margin-bottom: 20px;
    margin-left: 20px;
    width: 60%;
  }

  .question {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .questions {
    color: #a3243d;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .answer {
    font-size: 14px;
    margin-top: 20px;
  }

  .special {
    color: #fe6847;
    font-size: 16px;
    font-weight: bold;
  }

  .finish {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .finish-line-20 {
    background-color: #fe6847;
    border-radius: 10px;
    height: 10px;
    width: 20%;
  }

  .finish-line-50 {
    background-color: #fe6847;
    border-radius: 10px;
    height: 10px;
    width: 50%;
  }

  .finish-line-70 {
    background-color: #fe6847;
    border-radius: 10px;
    height: 10px;
    margin-bottom: 30px;
    width: 70%;
  }

  .finish-line-80 {
    background-color: #fe6847;
    border-radius: 10px;
    height: 10px;
    width: 80%;
  }

  .finish-line-85 {
    background-color: #fe6847;
    border-radius: 10px;
    height: 10px;
    width: 85%;
  }

  .field-label {
    color: #374464;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .terms-label {
    font-size: 16px;
    font-weight: bold;
    padding-top: 10px;
  }

  .sell-field {
    width: 50%;
  }

  .next-btn {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    margin-left: 50%;
    margin-top: 20px;
    width: 50%;
  }

  .next-btn-1 {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    margin-left: 70%;
    margin-top: 20px;
    width: 30%;
  }

  .next-btn-2 {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    margin-top: 30px;
    width: 50%;
  }

  .previous-btn {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    margin-top: 20px;
    text-align: center;
    width: 30%;
  }

  .send-btn {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    margin-top: 50px;
    width: 100%;
  }

  .get-your {
    font-size: 18px;
    font-weight: bold;
  }

  .free-quote {
    color: #a3243d;
    font-size: 18px;
    font-weight: bold;
  }

  .service-option {
    background-color: #f49f10;
    margin-bottom: 25px;
    margin-top: 25px;
    padding: 60px;
  }

  .i-agree {
    background-color: #f49f10;
    margin-bottom: 25px;
    margin-top: 25px;
    padding: 10px;
  }

  .property-sell-p {
    font-weight: lighter;
    padding-top: 10px;
  }

  .privacy-policy {
    color: #374464;
    font-size: 15px;
    font-weight: bold;
  }

  .terms-and-conditions {
    color: #a3243d;
    font-size: 15px;
    font-weight: bold;
  }

  .form-field {
    margin-bottom: 6px;
    position: relative;

    label {
      color: #333;
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
      line-height: 21px;
      margin-bottom: 3px;

      &:not(.ant-checkbox-wrapper) {
        width: 100%;
      }
    }
  }

  .head-label {
    font-size: 18px;
    font-weight: bold;
  }

  .faqs-content {
    color: gray;
    font-size: 16px;
    font-weight: bold;
  }

  .faqs-content-link {
    color: gray;
    font-size: 15px;
    font-weight: bold;
  }

  .faqs-content-body {
    color: gray;
    font-size: 14px;
    font-weight: normal;
  }

  .blog {
    background-color: #3b425e;
    font-size: 20px;
    font-weight: bold;
    margin: auto;
    padding: 30px;
    width: 90%;
  }

  .blog-title {
    color: #fff;
  }

  .blog-content {
    color: #000;
    font-size: 13px;
    font-weight: bold;
    margin-top: 20px;
  }

  .blog-body {
    color: gray;
    font-size: 13px;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 90%;
  }

  .blog-btn {
    background-color: #374464;
    color: #fff;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .blog-btn:hover {
    background-color: #f49f10;
    border: 1px solid #f49f10;
  }

  .panel-design {
    background-color: #fff;
    margin-bottom: 25px;

    .panel-header {
      background-color: #f4f7f9;
      border: 1px solid #ddd;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #000;
      display: block;
      margin-bottom: -1px;
      padding: 10px 15px;
      position: relative;

      >h3 {
        font-size: 16px;
        margin-bottom: 0;
      }
    }

    .panel-body {
      background-color: #fff;
      border: 1px solid #ddd;
      display: block;
      margin-bottom: -1px;
      padding: 10px 15px;
      position: relative;

      table {
        border: 1px solid #f3f3f3;
      }
    }

    .panel-with-border {
      .ant-row {
        .ant-col {
          border-bottom: 1px solid #e7e7e7;
          margin-bottom: 12px;
          padding-bottom: 12px;
        }

        .ant-col:last-child {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      span {
        display: block;
        font-size: 13px;
      }

      p {
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 4px;
      }

      p,
      .attachments-preview {
        font-size: 15px;
      }

      .attachments-preview {
        margin-top: 4px;
      }

      button {
        span {
          display: inline-block;
        }
      }
    }
  }
}

// save order table starts
.save-order-table {
  th {
    white-space: nowrap;
  }

  tr {
    td,
    th {
      padding: 0.75rem 0.3rem;
      vertical-align: middle;
    }
  }
}

.custom-loader-back-active {
  height: 100vh !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2147483647;
}

.custom-loader {
  // position: absolute;
  // top: 45px !important;
  top: 53px !important;
}

.table.borderless {
  td,
  th {
    border: 0;
  }
}

.ant-btn-success {
  background: #96bf48;
  border: 1px solid #99bd56;
  color: #fff;

  &:active,
  &:hover,
  &:focus {
    background: darken($color: #99bd56, $amount: 8);
    border-color: darken($color: #99bd86, $amount: 8);
    color: #fff;
  }
}

// design-holder starts

.designer-section {
  background: var(--white);
  margin: 15px auto;
  width: fit-content;

  .design-holder {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3;
  }
}

.header-text {
  color: var(--primary);
  font-weight: 600;
}

// social login starts

.social-login {
  margin-top: 20px;
  text-align: center;

  button {
    border: 0;
    border-radius: 3px;
    box-shadow: 0 2px 4px #d2d2d2;
    height: 37px;
    margin-right: 20px;
    width: 40%;

    img {
      height: 25px;
      margin-right: 6px;
      width: 25px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

// ant-popover-inner-content action buttons

.ant-popover-inner-content .action-buttons {
  min-width: 100px;

  ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    li {
      cursor: pointer;
      display: block;

      a {
        border-bottom: 1px solid var(--light-gray);
        color: #444;
        display: block;
        padding: 5px 0;
        text-align: left;

        i {
          margin-right: 6px;
          position: relative;
          top: 2px;
        }
      }
    }

    &:last-child {
      a {
        border-bottom: 0;
      }
    }
  }
}

.company-name {
  font-size: 17px;
  font-weight: bold;
  text-align: right;

  span {
    color: var(--light-blue);
    font-weight: normal;
  }
}

// custom-template-left starts

.custom-template-left,
.custom-template-right,
.custom-template-center {
  height: 91.7vh;
  overflow-y: auto;
}

.custom-template-center {
  overflow: auto;
  padding: 5px;
}

.custom-template-left {
  overflow-x: hidden;
  padding-right: 0 !important;

  ul.ant-menu {
    border-right: 0;

    li.ant-menu-submenu {
      border-bottom: 1px solid var(--light-gray);
      padding: 6px 0;
    }
  }
}

.custom-template-right {
  .template-controls {
    .footer-buttons,
    .top-save-button {
      background: var(--white);
      padding: 5px;
      position: fixed;
      text-align: center;
      width: 16.43%;
      z-index: 100;
    }

    .top-save-button {
      border-bottom: 1px solid #e9e9e9;

      button {
        width: 94%;
      }
    }

    .footer-buttons {
      border-top: 1px solid #e9e9e9;
      bottom: 0;

      button {
        background: transparent;
        border: transparent;
        color: var(--black);

        &:first-child {
          color: #f00;
        }

        &:last-child {
          color: var(--primary);
        }
      }
    }

    .template-name {
      font-size: 17px;
      font-weight: 600;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 50px;
    }

    .customizable-fields {
      margin-bottom: 40px;
      padding: 10px;

      .selected-item {
        margin-bottom: 10px;
      }

      .ant-divider .ant-divider-inner-text {
        color: var(--secondary);
        font-size: 14px;
        font-weight: 600;
      }

      .ant-divider-horizontal.ant-divider-with-text {
        margin: 8px 0;
      }

      .custom-input-box,
      .custom-select-box {
        margin-bottom: 5px;
      }

      .ant-col-lg-6,
      .ant-col-lg-12 {
        .ant-select-selector,
        input[type="text"],
        input[type="password"],
        input[type="number"],
        input[type="date"],
        .ant-picker,
        select {
          border-radius: 0 !important;
        }
      }

      .ant-select-selector,
      input[type="text"],
      input[type="password"],
      input[type="number"],
      input[type="date"],
      .ant-picker,
      select {
        border-radius: 2px !important;
        font-size: 12px !important;
        height: 31px !important;
        outline: none;
        padding: 5px 8px;
        position: relative;
        width: 100%;
      }

      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 21px;
      }
    }
  }
}

.wizard-section {
  @include card-bg;

  bottom: 0;
  display: flex;
  left: 0;
  padding: 10px 20px;
  position: fixed;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 999;

  .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) .ant-steps-item {
    padding-bottom: 4px;
  }

  .wizard-steps {
    padding-right: 20px;
    width: 60%;

    .ant-steps-item-title {
      font-size: 14px;
    }
  }

  .action-items {
    border-left: 1px solid var(--light-gray);
    padding-left: 20px;
    width: 40%;

    button {
      margin-right: 10px;
    }
  }
}

.ant-layout-sider-children {
  background: #fbfbfb;
}

.ant-checkbox-wrapper.middle .ant-checkbox {
  top: 0;
}

.same-as-shipping-container {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: -29px;

  i {
    color: #a3a3a3;
  }
}

.field-array {
  display: flex;

  >.ant-space-item {
    flex: 1;

    .ant-skeleton-element {
      display: block;
      flex: 1;

      .ant-skeleton-input {
        flex: 1;
      }
    }
  }
}

.table-layout {
  .table-title {
    color: #012970;
    font-size: 20px;
    margin-bottom: 14px;
    margin-top: 18px;
  }

  .add-button {
    display: none;
    margin-bottom: 15px;

    @media (max-width: 900px) {
      display: block;
    }
  }

  .custom-background {
    padding: 0;
  }
}

.terms-condition-content {
  max-width: 60ch;

  @media (max-width: 1024px) {
    max-width: 40ch;
  }

  @media (max-width: 768px) {
    max-width: 30ch;
  }
}

.custom-clearance {
  .customs-border {
    border-left: 15px solid #fff;
  }

  .ant-table-cell.bg-white {
    background: #fff !important;
    padding-left: 20px !important;
  }
}

.custom-clearance,
.sales-quotations {
  .ant-btn-success[disabled] {
    background: #85a842 !important;
    border: 1px solid #6e8b37 !important;
    color: #fff !important;
    height: auto;
  }
}

.amount-words {
  color: gray;
  font-style: italic;
  margin-left: auto;
  width: 80%;
}

.filter-section {
  background: var(--white);

  // background: #f7f7f7;
  min-height: 100vh;
  padding: 29px 18px;
  width: 100%;
}

.filter-section .add-new-invoice {
  width: 100%;
}

.filter-section form {
  margin-top: 15px;
}

.filter-section form .form-fields {
  margin-bottom: 8px;
}

.filter-section form .form-fields label {
  color: #5b5b5b;
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2px;
  text-transform: capitalize;
}

.filter-section form .form-fields:last-child,
.filter-section .form-fields:last-child {
  border-top: 1px solid #d9d9d9;
  margin-top: 14px;
}

.filter-section .form-fields:last-child button {
  margin-top: 14px;
  width: 100%;
}

.filter-section form .form-fields .search {
  margin-top: 14px;
  width: 100%;
}

ul.filter-buttons {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.filter-buttons li {
  display: inline-block;
}

ul.filter-buttons li a {
  color: #333;
  padding: 5px 10px;
}

ul.filter-buttons li a i {
  color: #026ec1;
  margin-right: 8px;
}

// add-new-invoice-popup starts

.ant-modal-content .ant-modal-title {
  font-size: 16px;
  font-weight: 600;
}

.ant-modal-body .links {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 #e0e0e0;
  padding: 20px;
  text-align: center;
}

.ant-modal-body .links img {
  height: 45px;
  margin-bottom: 10px;
  width: auto;
}

.ant-modal-body .links h4 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}

// add-new-invoice-popup ends here

// top-filter-options starts here

.top-filter-options {
  margin-bottom: 7px;
  margin-top: 23px;
  padding: 0 15px;
  width: 100%;
}

.top-filter-options h2 {
  font-size: 19px;
}

.top-filter-options .exports-and-settings {
  display: none;
  width: 100%;
}

.top-filter-options .exports-and-settings ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
}

.top-filter-options .exports-and-settings ul li {
  display: inline-block;
  padding: 0 6px;
}

.top-filter-options .exports-and-settings ul li a {
  display: block;
}

.top-filter-options button {
  height: 31px;
  padding: 2px 12px;
}

.app-sidebar {
  min-height: 86vh;
}

.arrange-column .action-item {
  margin-bottom: 20px;
  width: 100%;
}

.income-container {
  padding: 0 14px;
}

.income-container .custom-background {
  padding: 0 !important;
}

.acc-range {
  align-self: center;
}

.invoice-section.border-bottom {
  border-bottom: 1px sold #eee;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.invoice-section {
  background-color: #fff;
  margin-bottom: 25px;
  position: relative;
  width: 100%;

  h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  h3 {
    font-size: 17px;
    margin-bottom: 0;
  }

  .form-field {
    position: relative;

    label {
      color: var(--black);
      display: inline-block;
      font-size: 11.5px;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 5px;
      text-transform: capitalize;

      &:not(.ant-checkbox-wrapper) {
        width: 100%;
      }
    }
  }

  label:not(.ant-checkbox-wrapper) span {
    color: #f44336;
    margin-left: 4px;
  }

  .buyer-seller-details {
    h4 {
      font-weight: 600;
    }

    div {
      margin-bottom: 5px;
      text-transform: capitalize;
    }

    div:last-child {
      margin-bottom: 0;
    }
  }

  .panel-design {
    position: relative;
    width: 100%;

    .list-group-item:nth-child(2) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 16px;
    }
  }
}

.invoice-section.panel-design .list-details {
  background: #f4f7f9 !important;
  border-color: #ddd;
  color: #000;
}

.invoice-section.panel-design .list-group-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: block;
  margin-bottom: -1px;
  padding: 8px 15px;
  position: relative;
}

.invoice-section.panel-design .list-group-item h2 {
  margin-bottom: 0;
}

.invoice-section button.success {
  bottom: -15px;
  left: 13px;
  padding: 3px 6px;
  position: absolute;
}

.invoice-section button.success.preview-changes {
  bottom: 0;
  left: unset;
  position: relative;
}

.save-changes {
  display: block;
  text-align: center;
  width: 100%;
}

.save-changes button {
  margin: 0 10px;
}

.line-item-footer {
  display: flex;
  float: right;
}

.line-item-footer-content {
  padding: 5px;
}

.invoice-attachment-container {
  padding: 25px 25px 25px 0;
}

.company-list {
  width: 100%;

  .list {
    float: left;
    padding: 10px;
    width: 50%;

    i.company {
      background: var(--gradient-background);
      border-radius: 50%;
      color: var(--white);
      display: block;
      font-size: 40px;
      height: 74px;
      line-height: 73px;
      text-align: center;
      width: 74px;
    }

    .details {
      border-radius: 5px;
      box-shadow: var(--default-box-shadow);
      margin-bottom: 20px;
      padding: 20px;
    }

    img {
      display: block;
      margin: 0 auto;
      width: 52px;
    }

    h4 {
      color: var(--black);
      font-size: 16px;
      margin-top: 10px;
    }

    .other-specs {
      font-size: 14px;
      margin-bottom: 4px;
    }

    .ant-col {
      .other-specs:last-child {
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 900px) {
  .company-list {
    .list {
      float: left;
      width: 33.333%;
    }
  }
}

@media (max-width: 600px) {
  .company-list {
    .list {
      float: left;
      width: 50%;
    }
  }
}

@media (max-width: 400px) {
  .company-list {
    .list {
      float: left;
      width: 100%;
    }
  }
}

.rural {
  margin: auto;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 60%;

  .site-navigation-steps {
    background-color: #374464;
    border-radius: 5px;
    color: #f49f10;
    font-size: 10px;
    margin-bottom: 40px;
    padding-right: 10px;
    padding-top: 10px;
  }

  .rural-forms {
    border: 1px solid #ececec;
    border-radius: 10px;
    padding: 30px;
  }

  .intro {
    .list-title {
      color: #012970;
      font-size: 18px;
      font-weight: bold;
    }

    //   padding-right: 150px;
  }

  .form-field {
    margin-bottom: 15px;
    margin-top: 10px;
    position: relative;

    label {
      color: #333;
      display: inline-block;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .head-label {
    font-size: 22px;
    font-weight: bold;
  }

  .field-label {
    color: #374464;
    font-size: 16px;
    font-weight: bold;
  }

  .field-label-1 {
    font-size: 17px;
    font-weight: bold;
    margin-top: 20px;
  }

  .content {
    font-size: 15px;
    font-weight: lighter;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .content1 {
    font-size: 12px;
    font-weight: lighter;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  .disclaimer {
    color: #a3243d;
    font-size: 18px;
    font-weight: bold;
  }

  .note {
    color: red;
  }

  .note-content {
    font-weight: lighter;
  }

  .previous-btn {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    margin-top: 20px;
    text-align: center;
    width: 40%;
  }

  .next-btn {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    margin-left: 60%;
    margin-top: 20px;
    width: 40%;
  }

  .invoice-img {
    align-items: center;
    background-color: #374464;
    color: #f49f10;
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: bold;
    height: 100px;
    justify-content: center;
  }

  .final-label {
    font-size: 13px;
    font-weight: bold;
  }

  .terms {
    border: 2px solid #ececec;
    border-radius: 5px;
    font-size: 14px;
    font-weight: lighter;
    margin-top: 30px;
    padding: 5px;
  }

  .doubt {
    font-size: 14px;
    font-weight: lighter;
    margin-top: 50px;
  }

  .thank {
    border: 2px solid greenyellow;
    border-radius: 5px;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 5px;
  }

  .submit-btn {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    margin-left: 20%;
    margin-top: 30px;
    width: 60%;
  }
}

.overview {
  width: 100%;

  .transaction {
    margin-top: 20px;
    width: 22%;
  }

  .transaction-head {
    background-color: #3b425e;
    height: 180px;
  }

  .line {
    background-color: #f49f10;
    height: 2px;
    margin-left: 5%;
    margin-top: 20px;
    width: 90%;
  }

  .content {
    padding: 20px;
    padding-top: 40px;
  }

  .content-head {
    background-color: #a4243d;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
  }

  .content-body {
    border: 2px solid #ececec;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    margin-top: 30px;
    padding-bottom: 3px;
    padding-top: 3px;
  }

  .number {
    background-color: #a4243d;
    border-radius: 5px 0 0 5px;
    color: #fff;
    display: inline-flex;
    height: 100%;
    padding: 8px;
    text-align: center;
    width: 100%;
  }

  .name {
    color: #000;
    padding-left: 10px;
  }

  .content-foot {
    background-color: #a4243d;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    margin-top: 40px;
    padding: 10px;
  }

  .content-foot-body {
    font-size: 12px;
    font-weight: lighter;
  }

  .detail {
    margin-top: 20px;
    width: 77.8%;
  }

  .detail-head {
    background-color: #3b425e;
    color: #f49f10;
    font-size: 20px;
    font-weight: bold;
    height: 130px;
    margin-left: 2px;
    padding: 15px;
    padding-top: 50px;
  }

  .detail-content {
    padding: 30px;
    padding-top: 40px;
  }

  .detail-content-head {
    background-color: #f49f10;
    border-radius: 5px;
    color: #3b425e;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
  }

  .seller {
    margin-top: 30px;
    width: 67%;
  }

  .seller-head {
    background-color: #3a415d;
    border-radius: 5px 5px 0 0;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
  }

  .seller-body {
    background-color: #515e92;
    border-radius: 0 0 5px 5px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    line-height: 30px;
    padding: 10px;
  }

  .seller-witness {
    // margin-left: 35px;
    margin-top: 30px;
    width: 30%;
  }

  .seller-witness-head {
    background-color: #515e92;
    border-radius: 5px 5px 0 0;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
  }

  .seller-witness-body {
    background-color: #6e80cc;
    border-radius: 0 0 5px 5px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    height: 140px;
    line-height: 35px;
    padding: 10px;
  }

  .buyer {
    margin-top: 30px;
    width: 67%;
  }

  .buyer-head {
    background-color: #9c2038;
    border-radius: 5px 5px 0 0;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
  }

  .buyer-body {
    background-color: #a4243d;
    border-radius: 0 0 5px 5px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    line-height: 30px;
    padding: 10px;
  }

  .buyer-witness {
    margin-top: 30px;
    width: 30%;
  }

  .buyer-witness-head {
    background-color: #a4243d;
    border-radius: 5px 5px 0 0;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
  }

  .buyer-witness-body {
    background-color: #c4314e;
    border-radius: 0 0 5px 5px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    height: 140px;
    line-height: 35px;
    padding: 10px;
  }

  .terms {
    border: 2px solid #ececec;
    border-radius: 5px;
    margin-top: 30px;
    padding: 20px;
  }

  .terms-head {
    font-size: 22px;
    font-weight: bold;
  }

  .terms-body {
    font-size: 15px;
    margin-top: 10px;
  }

  .terms-body-1 {
    font-size: 16px;
    font-weight: bold;
    margin-left: 30px;
    margin-top: 30px;
  }

  .previous-btn {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    margin-top: 20px;
    text-align: center;
    width: 30%;
  }

  .next-btn {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    margin-left: 70%;
    margin-top: 20px;
    width: 30%;
  }
}

.maroon {
  align-items: center;
  background-color: #a3223e;
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  height: 50px;
  justify-content: center;
  padding: 25px;
  width: 50px;
}

.yellow {
  align-items: center;
  background-color: #f49f10;
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  height: 50px;
  justify-content: center;
  padding: 25px;
  width: 50px;
}

.white {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  color: #374464;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  height: 50px;
  justify-content: center;
  padding: 25px;
  width: 50px;
}

.purple {
  align-items: center;
  background-color: #8f2d56;
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  height: 50px;
  justify-content: center;
  padding: 25px;
  width: 50px;
}

.green {
  align-items: center;
  background-color: #218380;
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  height: 50px;
  justify-content: center;
  padding: 25px;
  width: 50px;
}

.sandal {
  align-items: center;
  background-color: #fbb13c;
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  height: 50px;
  justify-content: center;
  padding: 25px;
  width: 50px;
}

.pink {
  align-items: center;
  background-color: #d81159;
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  height: 50px;
  justify-content: center;
  padding: 25px;
  width: 50px;
}

.admin {
  .title {
    color: #f49f10;
    font-size: 28px;
    font-weight: bold;
  }

  .add-service-btn {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    float: right;
    font-size: 15px;
    font-weight: bold;
  }

  .line-btn {
    color: #026ec1;
  }

  .card {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
  }

  .sub-btn {
    background-color: #0d6efd;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
  }

  .list-title {
    color: #012970;
    font-size: 18px;
    font-weight: bold;
  }

  .quotation-text {
    font-size: 13px;
    line-height: 25px;
  }

  .delivered-btn {
    background-color: #198754;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
  }

  .delivered-btn:hover {
    background-color: #157347;
    border: 3px solid greenyellow;
    color: #fff;
  }

  .wip-btn {
    background-color: #ffc107;
    border-radius: 5px;
    font-weight: bold;
  }

  .wip-btn:hover {
    background-color: #ffca2c;
    border: 3px solid yellow;
    color: #000;
  }

  .quotation-btn {
    background-color: #0d6efd;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
  }

  .quotation-btn:hover {
    background-color: #0a58ca;
    border: 3px solid #0a58ca;
    color: #fff;
  }

  .inquiry-btn {
    background-color: #0dcaf0;
    border-radius: 5px;
    font-weight: bold;
  }

  .inquiry-btn:hover {
    background-color: #31d2f2;
    border: 3px solid #0dcaf0;
    color: #000;
  }

  .rejected-btn {
    background-color: #dc3545;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
  }

  .rejected-btn:hover {
    background-color: #bb2d3b;
    border: 3px solid red;
    color: #fff;
  }

  .qc-btn {
    background-color: #212529;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
  }

  .qc-btn:hover {
    background-color: #4d5154;
    border: 3px solid #4d5154;
    color: #fff;
  }

  .trash-btn {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
  }

  .trash-btn:hover {
    background-color: #4d5154;
    border: 3px solid #737f8d;
    color: #fff;
  }

  .rate {
    background-color: #dbdbdb;
    border-radius: 10px;
    color: #000;
    font-weight: bold;
    text-align: center;
    width: 30%;
  }

  .rate-1 {
    background-color: #0dcaf0;
    border-radius: 10px;
    color: #000;
    font-weight: bold;
    width: 20%;
  }

  .rate-2 {
    background-color: #0dcaf0;
    border-radius: 10px;
    color: #000;
    font-weight: bold;
    width: 40%;
  }

  .rate-3 {
    background-color: #0dcaf0;
    border-radius: 10px;
    color: #000;
    font-weight: bold;
    width: 60%;
  }

  .rate-4 {
    background-color: #0dcaf0;
    border-radius: 10px;
    color: #000;
    font-weight: bold;
    width: 80%;
  }

  .rate-5 {
    background-color: #0dcaf0;
    border-radius: 10px;
    color: #000;
    font-weight: bold;
    width: 100%;
  }

  .suggest-changes {
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    padding: 10px;
    width: 100%;
  }

  .change-alert {
    background-color: #fff3cd;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 15px;
    width: 100%;
  }

  .admin-content {
    background-color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    padding: 25px;
  }

  .property-search {
    background-color: #fff;
    margin-top: -10px;
    padding: 20px;
  }

  .image-view {
    background-color: #f49f10;
    border-radius: 5px;
    bottom: 16px;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    right: 16px;
  }

  .image-view-1 {
    background-color: #f44336;
    border-radius: 5px;
    bottom: 30px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    left: 16px;
    padding-left: 25px;
    padding-right: 25px;
    position: absolute;
  }

  .image-view-2 {
    background-color: #a4243d;
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    left: 0;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    position: absolute;
    top: 15px;
  }

  .exch-dark-btn {
    background-color: #374464;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    height: 50px;
    width: 100%;
  }

  .exch-green-btn {
    background-color: #198754;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    height: 50px;
    width: 100%;
  }

  .exch-yellow-btn {
    background-color: #ffc107;
    border-radius: 5px;
    font-size: 15px;
    font-weight: bold;
    height: 50px;
    width: 100%;
  }

  .exch-sky-btn {
    background-color: #0dcaf0;
    border-radius: 5px;
    font-size: 15px;
    font-weight: bold;
    height: 50px;
    width: 100%;
  }

  .exch-red-btn {
    background-color: #dc3545;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    height: 50px;
    width: 100%;
  }

  .exch-title {
    background-color: #cff4fc;
    border-radius: 5px;
    color: #055160;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    padding: 15px;
  }

  .view-exch {
    background-color: #d1e7dd;
    border-radius: 5px;
    color: #0f5132;
    font-size: 16px;
    line-height: 35px;
    padding: 15px;
  }

  .head {
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 60%;

    .image-view {
      background-color: #f49f10;
      border-radius: 5px;
      bottom: 16px;
      font-weight: bold;
      padding-left: 15px;
      padding-right: 15px;
      position: absolute;
      right: 16px;
    }

    .exch-dark-btn {
      background-color: #374464;
      border-radius: 5px;
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      height: 50px;
      width: 100%;
    }

    .exch-green-btn {
      background-color: #198754;
      border-radius: 5px;
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      height: 50px;
      width: 100%;
    }

    .exch-yellow-btn {
      background-color: #ffc107;
      border-radius: 5px;
      font-size: 15px;
      font-weight: bold;
      height: 50px;
      width: 100%;
    }

    .exch-sky-btn {
      background-color: #0dcaf0;
      border-radius: 5px;
      font-size: 15px;
      font-weight: bold;
      height: 50px;
      width: 100%;
    }

    .exch-red-btn {
      background-color: #dc3545;
      border-radius: 5px;
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      height: 50px;
      width: 100%;
    }

    .exch-title {
      background-color: #cff4fc;
      border-radius: 5px;
      color: #055160;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
      padding: 15px;
    }

    .view-exch {
      background-color: #d1e7dd;
      border-radius: 5px;
      color: #0f5132;
      font-size: 16px;
      line-height: 35px;
      padding: 15px;
    }
  }

  .head1 {
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;

    .title {
      margin-top: 30px;
    }

    .cards-2 {
      align-items: center;
      background-color: #fff;
      border: 1px solid lightgrey;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;
      margin-right: 120px;
      padding: 20px;
      text-align: center;
    }

    .card-head {
      color: #000;
      font-size: 17px;
      font-weight: bold;
    }
  }

  .rural-form {
    margin-bottom: 40px;
    margin-top: 40px;

    .site-navigation-steps {
      background-color: #374464;
      border-radius: 5px;
      color: #f49f10;
      font-size: 10px;
      margin-bottom: 60px;
      padding-right: 30%;
      padding-top: 10px;
    }

    .rural-forms {
      border: 1px solid #ececec;
      border-radius: 10px;
      padding: 30px;
    }
  }

  .service-field {
    border: 2px solid blue;
    border-radius: 10px;
    margin: 30px;
    padding: 15px;
  }
}

.previous-btn {
  background-color: #0d6efd;
  border-radius: 5px;
  color: #fff;
  margin-top: 20px;
  text-align: center;
  width: 30%;
}

.next-btn {
  background-color: #0d6efd;
  border-radius: 5px;
  color: #fff;
  margin-left: 70%;
  margin-top: 20px;
  width: 30%;
}

.inner-footer {
  padding: 20px;
  text-align: center;

  .content-1 {
    color: #012970;
    font-size: 14px;
  }

  .content-2 {
    color: #012970;
    font-size: 14px;
    font-weight: bold;
  }

  .content-3 {
    color: #012970;
    font-size: 13px;
  }
}

.reject-alert {
  background-color: #f8d7da;
  border-radius: 5px;
  padding: 25px;
}

.table-format {
  border: 1px solid #dbdbdb;
  border-collapse: collapse;
  font-size: 14px;
  padding: 10px;
}

.dashed {
  border-top: 3px dashed #bbb;
}

.view-icon {
  font-size: 18px;
  padding-left: 10px;
  padding-right: 15px;
}

.attachments-area {
  .clear-icon-vid {
    align-items: center;
    background: #fff;
    border-radius: 25px;
    bottom: 0;
    box-shadow: 5px 2px 5px #d3d3d3;
    color: red;
    cursor: pointer;
    display: flex;
    font-size: 25px;
    height: 20px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
  }

  .clear-icon-doc {
    align-items: center;
    background: #fff;
    border-radius: 25px;
    box-shadow: 5px 2px 5px #d3d3d3;
    color: red;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    height: 20px;
    justify-content: center;
    left: 35px;
    position: relative;
    top: 20px;
    width: 20px;
  }

  .vid {
    border-radius: 10px;
    cursor: pointer;
    margin: 10px 10px 10px 0;
    position: relative;
  }

  .clear-icon {
    align-items: center;
    background: #fff;
    border-radius: 25px;
    box-shadow: 5px 2px 5px #d3d3d3;
    color: red;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    height: 20px;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
  }

  .img {
    border-radius: 10px;
    cursor: pointer;
    height: 50px;
    margin: 10px 10px 10px 0;
    width: 50px;
  }
}

.sale-quotation {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}
